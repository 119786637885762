<template>
  <div class="head_pc">
    <div class="box">
      <select
        @click="axiosGet('select1and7')"
        v-model="dataToSql.para_01"
        name=""
        id=""
      >
        <option value="昆虫">昆虫</option>
        <option value="植物">植物</option>
      </select>
      <select
        @click="axiosGet('select1and7')"
        v-model="dataToSql.para_07"
        name=""
        id=""
      >
        <option value="生态照">生态照</option>
        <option value="标本照">标本照</option>
      </select>
      <div class="search_box">
        <input
          type="text"
          v-model="data.input"
          placeholder="请输入中文名、拉丁名、目、科等"
          @keydown.enter="search()"
          @keydown.esc="initData()"
        />

        <div class="btn" @click="search()">搜索</div>
        <div class="btn" @click="initData()">清除</div>
        <div class="text">{{ filter.length }}</div>
      </div>
    </div>
  </div>
  <div class="head_mobile">
    <div class="box">
      <div class="search_box">
        <input type="text" />
        <div class="text" @click="search()">搜索</div>
      </div>
    </div>
  </div>

  <div class="con_box">
    <div class="item" v-for="(item, index) in filter" :key="index">
      <div class="img_box">
        <img
          @click="getSrc(item.para_10)"
          :src="
            'https://zhongkun-1306785868.cos-website.ap-shanghai.myqcloud.com/img_D/' + item.para_09
          "
          alt=""
        />
      </div>
      <div class="font_bold">{{ item.para_02 }}</div>
      <div class="font_bold font_italic">{{ item.para_03 }}</div>
      <div class="font_gray">{{ item.para_04 + " " + item.para_05 }}</div>
      <div class="font_gray">{{ item.para_11 }}</div>
      <div class="font_gray">{{ item.para_12 }}</div>
      <div class="font_gray">{{ item.id }}</div>
    </div>
  </div>
  <div class="window">
    <div class="box">
      <img
        v-lazy
        @click="window('close')"
        :src="'https://zhongkun-1306785868.cos-website.ap-shanghai.myqcloud.com/img_D/' + data.src"
        alt=""
      />
    </div>
  </div>
  <div class="bottom_box">
    <div class="box">
      <select
        @change="axiosGet('select1and7')"
        v-model="dataToSql.para_01"
        name=""
        id=""
      >
        <option value="昆虫">昆虫</option>
        <option value="植物">植物</option>
      </select>
      <select
        name=""
        id=""
        v-model="dataToSql.para_07"
        @change="axiosGet('select1and7')"
      >
        <option value="生态照">生态照</option>
        <option value="标本照">标本照</option>
      </select>
      <div>{{ filter.length }}</div>
    </div>
  </div>
</template>

<script>
import { computed, onMounted, reactive } from "vue";

import axios from "axios";
import api from "@/api/index";
export default {
  setup() {
    let data = reactive({
      data: {},
      srv: "",
      input: "",
      filterInput: "",
    });
    let dataToSql = reactive({
      id: null,
      para_01: "昆虫",
      para_02: "",
      para_03: "",
      para_04: "",
      para_05: "",
      para_06: "",
      para_07: "生态照",
      para_08: "",
      para_09: "",
      para_10: "",
      para_11: "",
      para_12: "",
      para_13: "",
      para_14: "",
      para_15: "",
      command: "",
    });

    onMounted(() => {
      axiosGet("select1and7");
    });
    const getSrc = (src) => {
      window("open");
      data.src = src;
    };

    const search = () => {
      data.filterInput = "*%$";
      setTimeout(() => {
        data.filterInput = data.input;
      }, 10);
    };
    const initData = () => {
      data.filterInput = "";
    };

    const window = (para) => {
      let window = document.querySelector(".window");
      if (para == "open") {
        window.style.display = "block";
      } else if (para == "close") {
        window.style.display = "none";
      }
    };
    const axiosGet = (command) => {
      dataToSql.command = command;
      axios({
        method: "get",
        url: api.host + api.selectUniversal,
        params: dataToSql,
      }).then((res) => {
        data.data = res.data.results;
      });
    };

    const filter = computed(() => {
      let item = [];
      let input = data.filterInput.trim().toLocaleLowerCase();

      for (let k in data.data) {
        // item.push(data.data[k]);
        if (input == "") {
          item.push(data.data[k]);
        } else {
          // item.push(data.data[0]);
          if (data.data[k].para_02.includes(input)) {
            item.push(data.data[k]);
          } else if (
            data.data[k].para_03.trim().toLocaleLowerCase().includes(input)
          ) {
            item.push(data.data[k]);
          } else if (data.data[k].para_04.includes(input)) {
            item.push(data.data[k]);
          } else if (data.data[k].para_05.includes(input)) {
            item.push(data.data[k]);
          } else if (data.data[k].para_06.includes(input)) {
            item.push(data.data[k]);
          } else if (data.data[k].para_11.includes(input)) {
            item.push(data.data[k]);
          } else if (data.data[k].para_12.includes(input)) {
            item.push(data.data[k]);
          } else if (data.data[k].para_13.includes(input)) {
            item.push(data.data[k]);
          } else if (data.data[k].para_14.includes(input)) {
            item.push(data.data[k]);
          }
        }
      }

      return item;
    });

    return {
      initData,
      search,
      filter,
      data,
      dataToSql,
      axiosGet,
      getSrc,
      window,
    };
  },
};
</script>

<style lang="less" scoped>
.search_box {
  width: 500px;
  input {
    @media screen and (min-width: 600px) {
      width: 50%;
      margin: 0;
    }
  }
  .btn {
    @media screen and (min-width: 600px) {
      padding: 4px 20px;
      border-radius: 5px;
    }
  }
  .btn:hover {
    @media screen and (min-width: 600px) {
      background-color: rgb(220, 220, 220);
    }
  }
}
.con_box {
  display: flex;
  flex-wrap: wrap;
  padding: 0 10px;
  justify-content: space-around;
  .item {
    // border: 1px solid red;
    width: 220px;
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 0 0 30px 0;
    @media screen and (max-width: 600px) {
      width: 40vw;
    }

    .img_box {
      // border: 1px solid red;
      width: 200px;
      height: 200px;
      box-shadow: 0 0 2 2 rgb(200, 200, 200);
      margin: 0 0 8px 0;
      img {
        border-radius: 5px;
        width: 100%;
        height: 100%;
        object-fit: contain;
      }
      @media screen and (max-width: 600px) {
        width: 35vw;
        height: 35vw;
      }
    }
  }
}
</style>
