import {
  createRouter,
  // createWebHistory,
  createWebHashHistory,
} from "vue-router";
import HomeView from "../views/HomeView.vue";
import MyPhoto from "../views/MyPhoto.vue";
import WebCollection from "../views/WebCollection.vue";
import MyDrawing from "../views/MyDrawing.vue";
import DrawingInk from "../views/DrawingInk.vue";

const routes = [
  // {
  //   path: "/",
  //   name: "WebCollection",
  //   component: () =>
  //     import(
  //       /* webpackChunkName: "WebCollection" */ "../views/WebCollection.vue"
  //     ),
  // },
  {
    path: "/",
    name: "home",
    component: DrawingInk,
  },

  {
    path: "/DrawingInk",
    name: "DrawingInk",
    component: DrawingInk,
  },
  {
    path: "/MyPhoto",
    name: "MyPhoto",
    component: MyPhoto,
  },
  {
    path: "/ShoppingList",
    name: "shoppingList",
    component: () =>
      import(
        /* webpackChunkName: "ShoppingList" */ "../views/ShoppingList.vue"
      ),
  },
  {
    path: "/ExportCover",
    name: "ExportCover",
    component: () =>
      import(/* webpackChunkName: "ExportCover" */ "../views/ExportCover.vue"),
  },
  {
    path: "/ExportCoverA",
    name: "ExportCoverA",
    component: () =>
      import(
        /* webpackChunkName: "ExportCoverA" */ "../views/ExportCoverA.vue"
      ),
  },
  {
    path: "/ExportCoverB",
    name: "ExportCoverB",
    component: () =>
      import(
        /* webpackChunkName: "ExportCoverB" */ "../views/ExportCoverB.vue"
      ),
  },
  {
    path: "/ThreeList",
    name: "ThreeList",
    component: () =>
      import(/* webpackChunkName: "ThreeList" */ "../views/ThreeList.vue"),
  },
  {
    path: "/QiSiBoPrice",
    name: "QiSiBoPrice",
    component: () =>
      import(/* webpackChunkName: "QiSiBoPrice" */ "../views/QiSiBoPrice.vue"),
  },
  {
    path: "/InsectIntriduction",
    name: "InsectIntriduction",
    component: () =>
      import(
        /* webpackChunkName: "InsectIntriduction" */ "../views/InsectIntriduction.vue"
      ),
  },
  {
    path: "/QiSiBo",
    name: "QiSiBo",
    component: () =>
      import(/* webpackChunkName: "QiSiBo" */ "../views/QiSiBo.vue"),
  },
  {
    path: "/UpList",
    name: "UpList",
    component: () =>
      import(/* webpackChunkName: "UpList" */ "../views/UpList.vue"),
  },
  {
    path: "/PriceList",
    name: "PriceList",
    component: () =>
      import(/* webpackChunkName: "QiSiBo" */ "../views/PriceList.vue"),
  },
  {
    path: "/ExportImg",
    name: "ExportImg",
    component: () =>
      import(/* webpackChunkName: "ExportImg" */ "../views/ExportImg.vue"),
  },
  {
    path: "/ExportImgInk",
    name: "ExportImgInk",
    component: () =>
      import(
        /* webpackChunkName: "ExportImgInk" */ "../views/ExportImgInk.vue"
      ),
  },
  {
    path: "/PrintCertificate",
    name: "PrintCertificate",
    component: () =>
      import(
        /* webpackChunkName: "PrintCertificate" */ "../views/PrintCertificate.vue"
      ),
  },
  {
    path: "/PrintLabel",
    name: "PrintLabel",
    component: () =>
      import(/* webpackChunkName: "PrintLabel" */ "../views/PrintLabel.vue"),
  },
  {
    path: "/WebPhotoHui",
    name: "WebPhotoHui",
    component: () =>
      import(/* webpackChunkName: "WebPhotoHui" */ "../views/WebPhotoHui.vue"),
  },

  {
    path: "/CePr",
    name: "CePr",
    component: () => import(/* webpackChunkName: "CePr" */ "../views/CePr.vue"),
  },
  {
    path: "/UploadDataHui",
    name: "UploadDataHui",
    component: () =>
      import(
        /* webpackChunkName: "UploadDataHui" */ "../views/UploadDataHui.vue"
      ),
  },
  {
    path: "/InsectBreedDetail",
    name: "InsectBreedDetail",
    component: () =>
      import(
        /* webpackChunkName: "InsectBreedDetail" */ "../views/InsectBreedDetail.vue"
      ),
  },

  {
    path: "/WangGoods",
    name: "WangGoods",
    component: () =>
      import(/* webpackChunkName: "WangGoods" */ "../views/WangGoods.vue"),
  },
  {
    path: "/ShopMaterial",
    name: "ShopMaterial",
    component: () =>
      import(
        /* webpackChunkName: "ShopMaterial" */ "../views/ShopMaterial.vue"
      ),
  },
  {
    path: "/UserData",
    name: "UserData",
    component: () =>
      import(/* webpackChunkName: "UserData" */ "../views/UserData.vue"),
  },
  {
    path: "/UploadDataWang",
    name: "UploadDataWang",
    component: () =>
      import(
        /* webpackChunkName: "UploadDataWang" */ "../views/UploadDataWang.vue"
      ),
  },
  {
    path: "/AboutMe",
    name: "AboutMe",
    component: () =>
      import(/* webpackChunkName: "AboutMe" */ "../views/AboutMe.vue"),
  },
  {
    path: "/DrawingAi",
    name: "DrawingAi",
    component: () =>
      import(/* webpackChunkName: "DrawingAi" */ "../views/DrawingAi.vue"),
  },
  {
    path: "/DrawingDigital",
    name: "DrawingDigital",
    component: () =>
      import(
        /* webpackChunkName: "DrawingDigital" */ "../views/DrawingDigital.vue"
      ),
  },
  {
    //待删除
    path: "/DeletedData",
    name: "DeletedData",
    component: () =>
      import(/* webpackChunkName: "DeletedData" */ "../views/DeletedData.vue"),
  },
  {
    //待删除
    path: "/EpubDetail",
    name: "EpubDetail",
    component: () =>
      import(/* webpackChunkName: "EpubDetail" */ "../views/EpubDetail.vue"),
  },
  {
    path: "/VideoClipNote",
    name: "VideoClipNote",
    component: () =>
      import(
        /* webpackChunkName: "VideoClipNote" */ "../views/VideoClipNote.vue"
      ),
  },
  {
    path: "/AudioLocal",
    name: "AudioLocal",
    component: () =>
      import(/* webpackChunkName: "AudioLocal" */ "../views/AudioLocal.vue"),
  },
  {
    path: "/PdfNoteList",
    name: "PdfNoteList",
    component: () =>
      import(/* webpackChunkName: "PdfNote" */ "../views/PdfNoteList.vue"),
  },
  {
    path: "/PdfNote",
    name: "PdfNote",
    component: () =>
      import(/* webpackChunkName: "PdfNote" */ "../views/PdfNote.vue"),
  },
  {
    path: "/VideoLocal",
    name: "VideoLocal",
    component: () =>
      import(/* webpackChunkName: "VideoLocal" */ "../views/VideoLocal.vue"),
  },
  {
    path: "/VideoData",
    name: "VideoData",
    component: () =>
      import(/* webpackChunkName: "VideoData" */ "../views/VideoData.vue"),
  },
  {
    path: "/PrivateGallery",
    name: "PrivateGallery",
    component: () =>
      import(
        /* webpackChunkName: "PrivateGallery" */ "../views/PrivateGallery.vue"
      ),
  },
  {
    path: "/MusicData",
    name: "MusicData",
    component: () =>
      import(/* webpackChunkName: "MusicData" */ "../views/MusicData.vue"),
  },
  {
    path: "/UploadFile",
    name: "UploadFile",
    component: () =>
      import(/* webpackChunkName: "BookList" */ "../views/UploadFile.vue"),
  },
  {
    path: "/BookList",
    name: "BookList",
    component: () =>
      import(/* webpackChunkName: "BookList" */ "../views/BookList.vue"),
  },
  {
    path: "/BookDetail",
    name: "BookDetail",
    component: () =>
      import(/* webpackChunkName: "BookDetail" */ "../views/BookDetail.vue"),
  },
  {
    path: "/PlecoList",
    name: "PlecoList",
    component: () =>
      import(/* webpackChunkName: "PlecoList" */ "../views/PlecoList.vue"),
  },
  {
    path: "/about",
    name: "about",

    component: () =>
      import(/* webpackChunkName: "about" */ "../views/AboutView.vue"),
  },
  {
    path: "/WebPhoto",
    name: "WebPhoto",
    component: () =>
      import(/* webpackChunkName: "WebPhoto" */ "../views/WebPhoto.vue"),
  },

  {
    path: "/MyDrawing",
    name: "MyDrawing",
    component: () =>
      import(/* webpackChunkName: "WebPhoto" */ "../views/MyDrawing.vue"),
  },
  {
    path: "/PlecopteraImg",
    name: "PlecopteraImg",
    component: () =>
      import(
        /* webpackChunkName: "PlecopteraImg" */ "../views/PlecopteraImg.vue"
      ),
  },

  {
    path: "/EncryptedData",
    name: "EncryptedData",
    component: () =>
      import(
        /* webpackChunkName: "EncryptedData" */ "../views/EncryptedData.vue"
      ),
  },
  {
    path: "/WebCollection",
    name: "WebCollection",
    component: () =>
      import(
        /* webpackChunkName: "WebCollection" */ "../views/WebCollection.vue"
      ),
  },
  {
    path: "/DownloadFile",
    name: "DownloadFile",
    component: () =>
      import(
        /* webpackChunkName: "DownloadFile" */ "../views/DownloadFile.vue"
      ),
  },
  {
    path: "/ShortcutKey",
    name: "ShortcutKey",
    component: () =>
      import(/* webpackChunkName: "ShortcutKey" */ "../views/ShortcutKey.vue"),
  },
  {
    path: "/UploadData",
    name: "UploadData",
    component: () =>
      import(/* webpackChunkName: "UploadData" */ "../views/UploadData.vue"),
  },
  {
    path: "/InsectKey",
    name: "InsectKey",
    component: () =>
      import(/* webpackChunkName: "UploadData" */ "../views/InsectKey.vue"),
  },
  {
    path: "/ImgArchives",
    name: "ImgArchives",
    component: () =>
      import(/* webpackChunkName: "ImgArchives" */ "../views/ImgArchives.vue"),
  },
];

const router = createRouter({
  // history: createWebHistory(process.env.BASE_URL),
  history: createWebHashHistory(),
  routes,
});

export default router;
