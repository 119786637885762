<template>
  <!-- <div class="head_pc">
    <div class="box">
      <div class="search_box">
        <input type="text" v-model="data.input" />

        <div class="text">{{ data.data.length }}</div>
      </div>
    </div>
  </div> -->
  <!-- <div class="head_mobile">
    <div class="box">
      <div class="search_box">
        <input type="text" v-model="data.input" />
      </div>
    </div>
  </div> -->

  <div class="con_box">
    <div class="item" v-for="(item, index) in filter" :key="index">
      <div class="img_box">
        <img
          @click="getSrc(item.para_10)"
          :src="
            'https://zhongkun-1306785868.cos-website.ap-shanghai.myqcloud.com/img_D/' +
            item.para_09
          "
          alt=""
        />
      </div>
      <div>{{ item.id }}</div>
      <div class="font_bold">{{ item.para_05 }}</div>
    </div>
  </div>
  <div class="window">
    <div class="box">
      <img
        @click="window('close')"
        :src="
          'https://zhongkun-1306785868.cos-website.ap-shanghai.myqcloud.com/img_D/' +
          data.src
        "
        alt=""
      />
    </div>
  </div>
</template>

<script>
import { computed, onMounted, reactive } from "vue";

import axios from "axios";
import api from "@/api/index";
import { useRoute } from "vue-router";
export default {
  setup() {
    const route = new useRoute();
    let data = reactive({
      data: {},
      input: "",
      drawingStyle: route.query,
    });
    let dataToSql = reactive({
      id: null,
      para_01: "绘画作品",
      para_02: "钢笔画",
      para_03: "小程序",
      para_04: "",
      para_05: "",
      para_06: "",
      para_07: "",
      para_08: "",
      para_09: "",
      para_10: "",
      para_11: "",
      para_12: "",
      para_13: "",
      para_14: "",
      para_15: "",
      command: "",
      search: "",
    });

    onMounted(() => {
      axiosGet("selectByPara1and2and3desc");
    });

    const getSrc = (src) => {
      window("open");
      data.src = src;
    };

    const window = (para) => {
      let window = document.querySelector(".window");
      if (para == "open") {
        window.style.display = "block";
      } else if (para == "close") {
        window.style.display = "none";
      }
    };
    const axiosGet = (command) => {
      dataToSql.command = command;
      axios({
        method: "get",
        url: api.host + api.selectUniversal,
        params: dataToSql,
      }).then((res) => {
        data.data = res.data.results;
      });
    };

    const filter = computed(() => {
      let item = [];
      let input = data.input.trim().toLowerCase();
      for (let k in data.data) {
        if (input == "") {
          item.push(data.data[k]);
        } else {
          if (data.data[k].para_03.toLowerCase().includes(input)) {
            item.push(data.data[k]);
          }
        }
      }

      for (let i = 0; i < item.length; i++) {
        item[i].para_03 = item[i].para_03.split("  ").join("\n");
      }

      return item;
    });

    return {
      data,
      dataToSql,
      axiosGet,
      window,
      getSrc,
      filter,
    };
  },
};
</script>

<style lang="less" scoped>
.con_box {
  display: flex;
  flex-wrap: wrap;
  padding: 0 10px;
  justify-content: space-around;
  .item {
    // border: 1px solid red;
    width: 260px;
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 0 0 30px 0;
    @media screen and (max-width: 600px) {
      width: 40vw;
      font-size: 4px;
      color: gray;
      font-size: 0.8rem;
    }
    .img_box {
      // border: 1px solid red;
      width: 200px;
      height: 200px;
      box-shadow: 0 0 2 2 rgb(200, 200, 200);
      margin: 0 0 10px 0;
      img {
        border-radius: 5px;
        width: 100%;
        height: 100%;
        object-fit: contain;
        box-shadow: 0 0 2px 2px rgb(200, 200, 200);
      }
      @media screen and (max-width: 600px) {
        width: 35vw;
        height: 35vw;
      }
    }
  }
}
</style>
