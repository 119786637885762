<template>
  <div>
    <div class="head_02">
      <div class="inputBox">
        <input class="inputValue" type="text" v-model="data.inputValue" />
        <div class="number">{{ filter.length }}</div>
      </div>
    </div>
    <div class="box">
      <div v-for="(item, index) in filter" :key="index" class="drawing_box">
        <div class="img_box">
          <img
            :src="
              'https://zhongkun-1306785868.cos-website.ap-shanghai.myqcloud.com/img_D/' +
              item.para_09
            "
            alt=""
          />
        </div>
        <div>
          <div class="con_02_item">{{ "ID：" + item.id }}</div>
          <div class="con_02_item">{{ item.para_03 }}</div>
          <div class="con_02_item webSrc" @click="openWindow(item.para_02)">
            {{ item.para_02 }}
          </div>
        </div>
      </div>
    </div>
    <!-- 大图 -->
    <div class="bigImg_box">
      <div class="bigImg_shell" @click="changeSize()">
        <img alt="" class="bigImg" />
        <!-- <div class="msg"></div> -->
      </div>
    </div>
  </div>
</template>

<script>
import { onMounted, reactive } from "vue";
import api from "@/api/index";
import axios from "axios";
import { computed } from "@vue/reactivity";
export default {
  setup() {
    let data = reactive({
      data: {},
      classNumber: "",
      penDrawing: "",
      digitalDrawing: "",
      count: 0,
      inputValue: "",
    });
    let dataToSql = reactive({
      command: "",
      id: null,
      para_01: "网页收藏",
      para_02: "",
      para_03: "",
      para_04: "",
      para_05: "",
      para_06: "",
      para_07: "",
      para_08: "",
      para_09: "",
      para_10: "",
      para_11: "",
      para_12: "",
      para_13: "",
      para_14: "",
      para_15: "",
      search: "",
      page: 1,
    });

    onMounted(() => {
      axiosGet("selectByPara_01");
    });

    // 打开新窗口
    const openWindow = (src) => {
      window.open(src, "_blank");
    };

    const update = () => {
      data.inputValue = "*";
      setTimeout(() => {
        data.inputValue = "";
      }, 200);
    };

    const axiosGet = (command) => {
      if (command == "search") {
        if (dataToSql.search.trim() == "") {
          return alert("搜索框不可为空");
        } else {
          let box = document.querySelector(".bottom_box");
          box.style.display = "none";
        }
      }
      dataToSql.command = command;
      axios({
        method: "get",
        url: api.host + api.selectUniversal,
        params: dataToSql,
      }).then((res) => {
        if (command == "selectByPara_01") {
          data.data = res.data.results;
        } else if (command == "selectById") {
          let data = res.data.results[0];
          dataToSql.para_01 = data.para_01;
          dataToSql.para_02 = data.para_02;
          dataToSql.para_03 = data.para_03;
          dataToSql.para_04 = data.para_04;
          dataToSql.para_05 = data.para_05;
          dataToSql.para_06 = data.para_06;
          dataToSql.para_07 = data.para_07;
          dataToSql.para_08 = data.para_08;
          dataToSql.para_09 = data.para_09;
          dataToSql.para_10 = data.para_10;
          dataToSql.para_11 = data.para_11;
          dataToSql.para_12 = data.para_12;
          dataToSql.para_13 = data.para_13;
          dataToSql.para_14 = data.para_14;
          dataToSql.para_15 = data.para_15;
        } else if (command == "search") {
          data.data = res.data.results;
          update();
        } else if (command == "selectBookList") {
          data.data = res.data.results;
        } else if (command == "selectPageByPara_01") {
          data.data = res.data.results;
          data.minImg = res.data.results[0].para_10;
        } else if (command == "selectByPara1and2") {
          data.data = res.data.results;
          update();
        }
      });
    };

    // 更改图片尺寸
    const changeSize = () => {
      // 改变字体颜色
      // let color = document.querySelector(".close_box");
      let bigImg_box = document.querySelector(".bigImg_box");
      // let bigImg_shell = document.querySelector(".bigImg_shell");
      let imgSize = document.querySelector(".bigImg");
      if (data.count == 0) {
        imgSize.style.height = "200%";
        data.count = 1;
        // bigImg_shell.style.width = "100%";
      } else if (data.count == 1) {
        bigImg_box.style.display = "none";
        imgSize.style.height = "100%";
        data.count = 0;
      }
    };

    const getData = (para) => {
      // 激活选中的样式
      data.classNumber = para;
      dataToSql.para_02 = para;
      axiosGet("selectByPara1and2");
    };

    // 显示大图窗口
    const showBigimg = (imgName) => {
      let imgBox = document.querySelector(".bigImg_box");
      imgBox.style.display = "block";
      // 获取大图
      let url = document.querySelector(".bigImg");
      url.src =
        "https://zhongkun-1306785868.cos-website.ap-shanghai.myqcloud.com/img_D/" +
        imgName;
    };
    const filter = computed(() => {
      let item = [];
      let input = data.inputValue.trim().toLowerCase();
      if (input == "") {
        for (let k in data.data) {
          item.push(data.data[k]);
        }
      } else {
        for (let k in data.data) {
          if (data.data[k].para_02.toLowerCase().includes(input)) {
            item.push(data.data[k]);
          } else if (data.data[k].para_03.includes(input)) {
            item.push(data.data[k]);
          }
        }
      }
      return item;
    });
    return {
      data,
      getData,
      showBigimg,
      changeSize,
      axiosGet,
      update,
      filter,
      openWindow,
    };
  },
};
</script>

<style lang="less" scoped>
.head_02 {
  position: fixed;
  width: 100%;
  top: 40px;
  left: 0;
  height: 50px;
  z-index: 90;
  padding: 5px 80px;
  display: flex;
  justify-content: center;
  background-color: white;
  @media screen and (max-width: 600px) {
    display: none;
    // position: fixed;
    // top: 0;
    // left: 30vw;
    // right: 0;
    // height: 6vh;
    // display: flex;
    // // background-color: white;
    // align-items: center;
    // justify-content: right;
    // padding: 0;
    // box-shadow: none;
    // z-index: 99;
    // border: 1px solid rgb(237, 12, 159);
  }
  .inputBox {
    width: 100%;
    // margin: 0 0 20px 0;
    padding: 0 0 5px 0;
    display: flex;
    justify-content: center;
    height: 100%;
    align-items: center;
    @media screen and (max-width: 600px) {
      width: 30vw;
    }
    // font-weight: bold;
    .number {
      margin: 0;
    }

    .inputValue {
      margin: 0 0 0 80px;
    }
    input {
      width: 20%;
      padding: 5px 10px;
      border: 2px solid gray;
      border-radius: 5px;
      background-color: rgb(240, 240, 240);
    }
  }
  .item_active {
    margin: 0 30px 0 0;
    color: rgb(0, 0, 0);
    font-weight: bold;
  }
  .item {
    margin: 0 30px 0 0;
    color: gray;
  }
  .item:hover {
    color: blue;
  }
}
.box {
  margin: 0;
  background-color: white;
  // box-shadow: 0 0 2px 2px rgb(200, 200, 200);
  // border-radius: 10px;
  padding: 20px 80px;
  display: flex;
  justify-content: space-around;
  flex-wrap: wrap;
  @media screen and (max-width: 600px) {
    width: 100vw;
    padding: 1vh 1vw;
  }
  .bigImg_box {
    display: none;
    .bigImg_shell {
      overflow: auto;
      img {
        height: 100%;
        z-index: 99;
      }
    }
  }
  .drawing_box {
    border-bottom: 1px solid rgb(207, 207, 207);
    display: flex;
    // flex-direction: column;
    // align-items: center;
    // justify-content: center;
    width: 100%;
    margin: 30px 0 0 0;
    padding: 0 30px;
    .img_box {
      width: 50px;
      height: 50px;
      border: 1px solid rgb(220, 220, 220);
      margin: 0 20px 0 0;
      display: flex;
      align-items: center;
      @media screen and (max-width: 600px) {
        display: none;
      }
      img {
        width: 100%;
        height: 100%;
      }
    }
    .con_02_item {
      margin: 0 0 10px 0;
      display: flex;
      justify-content: left;
      @media screen and (max-width: 600px) {
        flex-wrap: wrap;
      }
    }
    .webSrc {
      color: rgb(95, 164, 191);
      font-weight: bold;
    }
    .webSrc:hover {
      color: red;
    }
  }
}
</style>
