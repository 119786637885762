import { createStore } from "vuex";
import createPersistedState from "vuex-persistedstate";

export default createStore({
  namespaced: true,
  state: {
    userName: "",
    purview: "",
    classNumber: "生态照",
    dataStatus: "",
    goBackPara: "0",
    // 权限管理
    pms_01: "",
    pms_02: "",
    pms_03: "",
    pms_04: "",
    pms_05: "",
    pms_06: "",
    pms_07: "",
  },
  getters: {},
  mutations: {
    addUserName(state, name) {
      state.userName = name;
    },
    // 权限
    addPurview(state, purview) {
      state.purview = purview;
    },
    addPms_01(state, pms_01) {
      state.pms_01 = pms_01;
    },
    addPms_02(state, pms_02) {
      state.pms_02 = pms_02;
    },
    addPms_03(state, pms_03) {
      state.pms_03 = pms_03;
    },
    addPms_04(state, pms_04) {
      state.pms_04 = pms_04;
    },
    addPms_05(state, pms_05) {
      state.pms_05 = pms_05;
    },
    addPms_06(state, pms_06) {
      state.pms_06 = pms_06;
    },
    addPms_07(state, pms_07) {
      state.pms_07 = pms_07;
    },
    addClassNumber(state, classNumber) {
      state.classNumber = classNumber;
    },
    addDataStatus(state, dataStatus) {
      state.dataStatus = dataStatus;
    },
    goBackPara(state, goBackPara) {
      state.goBackPara = goBackPara;
    },
  },
  actions: {},
  modules: {},
  plugins: [
    createPersistedState({
      storage: window.sessionStorage,
      key: "store",
      render(state) {
        return { ...state };
      },
    }),
  ],
});
